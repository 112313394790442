.navbar .collapsing .navbar-toggler {
  pointer-events: none;
}

.navbar-brand {
  font-size: $font-size-sm;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 0.05px;

  img {
    height: 50px;
  }
}
